const data = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [133, -27],
      },
      properties: {
        Year: 2010,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Australia',
        'Country of asylum (ISO)': 'AUS',
        'Resettlement arrivals': 17,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [4, 50.8333],
      },
      properties: {
        Year: 2010,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belgium',
        'Country of asylum (ISO)': 'BEL',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95, 60],
      },
      properties: {
        Year: 2010,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Canada',
        'Country of asylum (ISO)': 'CAN',
        'Resettlement arrivals': 20,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 62],
      },
      properties: {
        Year: 2010,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Sweden',
        'Country of asylum (ISO)': 'SWE',
        'Resettlement arrivals': 12,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97, 38],
      },
      properties: {
        Year: 2010,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'United States of America',
        'Country of asylum (ISO)': 'USA',
        'Resettlement arrivals': 24,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [133, -27],
      },
      properties: {
        Year: 2011,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Australia',
        'Country of asylum (ISO)': 'AUS',
        'Resettlement arrivals': 58,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [4, 50.8333],
      },
      properties: {
        Year: 2011,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belgium',
        'Country of asylum (ISO)': 'BEL',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95, 60],
      },
      properties: {
        Year: 2011,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Canada',
        'Country of asylum (ISO)': 'CAN',
        'Resettlement arrivals': 16,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 62],
      },
      properties: {
        Year: 2011,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Sweden',
        'Country of asylum (ISO)': 'SWE',
        'Resettlement arrivals': 12,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97, 38],
      },
      properties: {
        Year: 2011,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'United States of America',
        'Country of asylum (ISO)': 'USA',
        'Resettlement arrivals': 23,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [133, -27],
      },
      properties: {
        Year: 2012,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Australia',
        'Country of asylum (ISO)': 'AUS',
        'Resettlement arrivals': 37,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [4, 50.8333],
      },
      properties: {
        Year: 2012,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belgium',
        'Country of asylum (ISO)': 'BEL',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [25, 43],
      },
      properties: {
        Year: 2012,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Bulgaria',
        'Country of asylum (ISO)': 'BGR',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95, 60],
      },
      properties: {
        Year: 2012,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Canada',
        'Country of asylum (ISO)': 'CAN',
        'Resettlement arrivals': 29,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 53],
      },
      properties: {
        Year: 2012,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Ireland',
        'Country of asylum (ISO)': 'IRL',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 62],
      },
      properties: {
        Year: 2012,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Sweden',
        'Country of asylum (ISO)': 'SWE',
        'Resettlement arrivals': 88,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97, 38],
      },
      properties: {
        Year: 2012,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'United States of America',
        'Country of asylum (ISO)': 'USA',
        'Resettlement arrivals': 41,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [45, 40],
      },
      properties: {
        Year: 2013,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Armenia',
        'Country of asylum (ISO)': 'ARM',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [133, -27],
      },
      properties: {
        Year: 2013,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Australia',
        'Country of asylum (ISO)': 'AUS',
        'Resettlement arrivals': 141,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [4, 50.8333],
      },
      properties: {
        Year: 2013,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belgium',
        'Country of asylum (ISO)': 'BEL',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95, 60],
      },
      properties: {
        Year: 2013,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Canada',
        'Country of asylum (ISO)': 'CAN',
        'Resettlement arrivals': 45,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 53],
      },
      properties: {
        Year: 2013,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Ireland',
        'Country of asylum (ISO)': 'IRL',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [5.75, 52.5],
      },
      properties: {
        Year: 2013,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Netherlands',
        'Country of asylum (ISO)': 'NLD',
        'Resettlement arrivals': 14,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 62],
      },
      properties: {
        Year: 2013,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Sweden',
        'Country of asylum (ISO)': 'SWE',
        'Resettlement arrivals': 362,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97, 38],
      },
      properties: {
        Year: 2013,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'United States of America',
        'Country of asylum (ISO)': 'USA',
        'Resettlement arrivals': 45,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [133, -27],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Australia',
        'Country of asylum (ISO)': 'AUS',
        'Resettlement arrivals': 1820,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [13.3333, 47.3333],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Austria',
        'Country of asylum (ISO)': 'AUT',
        'Resettlement arrivals': 388,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [4, 50.8333],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belgium',
        'Country of asylum (ISO)': 'BEL',
        'Resettlement arrivals': 28,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95, 60],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Canada',
        'Country of asylum (ISO)': 'CAN',
        'Resettlement arrivals': 1083,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 56],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Denmark',
        'Country of asylum (ISO)': 'DNK',
        'Resettlement arrivals': 124,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [26, 64],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Finland',
        'Country of asylum (ISO)': 'FIN',
        'Resettlement arrivals': 431,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [2, 46],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'France',
        'Country of asylum (ISO)': 'FRA',
        'Resettlement arrivals': 40,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-2, 54],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum':
          'United Kingdom of Great Britain and Northern Ireland',
        'Country of asylum (ISO)': 'GBR',
        'Resettlement arrivals': 143,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 53],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Ireland',
        'Country of asylum (ISO)': 'IRL',
        'Resettlement arrivals': 89,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [9.5333, 47.1667],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Liechtenstein',
        'Country of asylum (ISO)': 'LIE',
        'Resettlement arrivals': 5,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [6.1667, 49.75],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Luxembourg',
        'Country of asylum (ISO)': 'LUX',
        'Resettlement arrivals': 28,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [5.75, 52.5],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Netherlands',
        'Country of asylum (ISO)': 'NLD',
        'Resettlement arrivals': 243,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 62],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Norway',
        'Country of asylum (ISO)': 'NOR',
        'Resettlement arrivals': 785,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [174, -41],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'New Zealand',
        'Country of asylum (ISO)': 'NZL',
        'Resettlement arrivals': 14,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [122, 13],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Philippines',
        'Country of asylum (ISO)': 'PHL',
        'Resettlement arrivals': 5,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 62],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Sweden',
        'Country of asylum (ISO)': 'SWE',
        'Resettlement arrivals': 714,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [8, 47],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Switzerland',
        'Country of asylum (ISO)': 'CHE',
        'Resettlement arrivals': 113,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-56, -33],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Uruguay',
        'Country of asylum (ISO)': 'URY',
        'Resettlement arrivals': 43,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97, 38],
      },
      properties: {
        Year: 2014,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'United States of America',
        'Country of asylum (ISO)': 'USA',
        'Resettlement arrivals': 271,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-64, -34],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Argentina',
        'Country of asylum (ISO)': 'ARG',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [133, -27],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Australia',
        'Country of asylum (ISO)': 'AUS',
        'Resettlement arrivals': 1502,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [13.3333, 47.3333],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Austria',
        'Country of asylum (ISO)': 'AUT',
        'Resettlement arrivals': 756,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [4, 50.8333],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belgium',
        'Country of asylum (ISO)': 'BEL',
        'Resettlement arrivals': 188,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28, 53],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belarus',
        'Country of asylum (ISO)': 'BLR',
        'Resettlement arrivals': 14,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95, 60],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Canada',
        'Country of asylum (ISO)': 'CAN',
        'Resettlement arrivals': 7924,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 56],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Denmark',
        'Country of asylum (ISO)': 'DNK',
        'Resettlement arrivals': 244,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [26, 64],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Finland',
        'Country of asylum (ISO)': 'FIN',
        'Resettlement arrivals': 376,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [2, 46],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'France',
        'Country of asylum (ISO)': 'FRA',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-2, 54],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum':
          'United Kingdom of Great Britain and Northern Ireland',
        'Country of asylum (ISO)': 'GBR',
        'Resettlement arrivals': 1199,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [9, 51],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Germany',
        'Country of asylum (ISO)': 'DEU',
        'Resettlement arrivals': 9,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-18, 65],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Iceland',
        'Country of asylum (ISO)': 'ISL',
        'Resettlement arrivals': 13,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 53],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Ireland',
        'Country of asylum (ISO)': 'IRL',
        'Resettlement arrivals': 149,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [12.8333, 42.8333],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Italy',
        'Country of asylum (ISO)': 'ITA',
        'Resettlement arrivals': 96,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [9.5333, 47.1667],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Liechtenstein',
        'Country of asylum (ISO)': 'LIE',
        'Resettlement arrivals': 18,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [6.1667, 49.75],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Luxembourg',
        'Country of asylum (ISO)': 'LUX',
        'Resettlement arrivals': 46,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 62],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Norway',
        'Country of asylum (ISO)': 'NOR',
        'Resettlement arrivals': 1684,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [174, -41],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'New Zealand',
        'Country of asylum (ISO)': 'NZL',
        'Resettlement arrivals': 75,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 62],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Sweden',
        'Country of asylum (ISO)': 'SWE',
        'Resettlement arrivals': 680,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [8, 47],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Switzerland',
        'Country of asylum (ISO)': 'CHE',
        'Resettlement arrivals': 552,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97, 38],
      },
      properties: {
        Year: 2015,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'United States of America',
        'Country of asylum (ISO)': 'USA',
        'Resettlement arrivals': 2192,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-64, -34],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Argentina',
        'Country of asylum (ISO)': 'ARG',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [133, -27],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Australia',
        'Country of asylum (ISO)': 'AUS',
        'Resettlement arrivals': 2520,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [13.3333, 47.3333],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Austria',
        'Country of asylum (ISO)': 'AUT',
        'Resettlement arrivals': 200,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [4, 50.8333],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belgium',
        'Country of asylum (ISO)': 'BEL',
        'Resettlement arrivals': 448,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95, 60],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Canada',
        'Country of asylum (ISO)': 'CAN',
        'Resettlement arrivals': 33266,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 56],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Denmark',
        'Country of asylum (ISO)': 'DNK',
        'Resettlement arrivals': 236,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [26, 59],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Estonia',
        'Country of asylum (ISO)': 'EST',
        'Resettlement arrivals': 11,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [26, 64],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Finland',
        'Country of asylum (ISO)': 'FIN',
        'Resettlement arrivals': 546,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [2, 46],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'France',
        'Country of asylum (ISO)': 'FRA',
        'Resettlement arrivals': 1190,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-2, 54],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum':
          'United Kingdom of Great Britain and Northern Ireland',
        'Country of asylum (ISO)': 'GBR',
        'Resettlement arrivals': 4369,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [9, 51],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Germany',
        'Country of asylum (ISO)': 'DEU',
        'Resettlement arrivals': 1215,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [20, 47],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Hungary',
        'Country of asylum (ISO)': 'HUN',
        'Resettlement arrivals': 15,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-18, 65],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Iceland',
        'Country of asylum (ISO)': 'ISL',
        'Resettlement arrivals': 56,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 53],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Ireland',
        'Country of asylum (ISO)': 'IRL',
        'Resettlement arrivals': 335,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [12.8333, 42.8333],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Italy',
        'Country of asylum (ISO)': 'ITA',
        'Resettlement arrivals': 482,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [24, 56],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Lithuania',
        'Country of asylum (ISO)': 'LTU',
        'Resettlement arrivals': 25,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [6.1667, 49.75],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Luxembourg',
        'Country of asylum (ISO)': 'LUX',
        'Resettlement arrivals': 52,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [25, 57],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Latvia',
        'Country of asylum (ISO)': 'LVA',
        'Resettlement arrivals': 6,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [7.4, 43.7333],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Monaco',
        'Country of asylum (ISO)': 'MCO',
        'Resettlement arrivals': 5,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [5.75, 52.5],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Netherlands',
        'Country of asylum (ISO)': 'NLD',
        'Resettlement arrivals': 601,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 62],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Norway',
        'Country of asylum (ISO)': 'NOR',
        'Resettlement arrivals': 2902,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [174, -41],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'New Zealand',
        'Country of asylum (ISO)': 'NZL',
        'Resettlement arrivals': 383,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-4, 40],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Spain',
        'Country of asylum (ISO)': 'ESP',
        'Resettlement arrivals': 289,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 62],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Sweden',
        'Country of asylum (ISO)': 'SWE',
        'Resettlement arrivals': 688,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [8, 47],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Switzerland',
        'Country of asylum (ISO)': 'CHE',
        'Resettlement arrivals': 524,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97, 38],
      },
      properties: {
        Year: 2016,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'United States of America',
        'Country of asylum (ISO)': 'USA',
        'Resettlement arrivals': 15479,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-64, -34],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Argentina',
        'Country of asylum (ISO)': 'ARG',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [133, -27],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Australia',
        'Country of asylum (ISO)': 'AUS',
        'Resettlement arrivals': 3656,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [13.3333, 47.3333],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Austria',
        'Country of asylum (ISO)': 'AUT',
        'Resettlement arrivals': 381,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [4, 50.8333],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belgium',
        'Country of asylum (ISO)': 'BEL',
        'Resettlement arrivals': 1191,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95, 60],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Canada',
        'Country of asylum (ISO)': 'CAN',
        'Resettlement arrivals': 11042,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-71, -30],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Chile',
        'Country of asylum (ISO)': 'CHL',
        'Resettlement arrivals': 66,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 56],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Denmark',
        'Country of asylum (ISO)': 'DNK',
        'Resettlement arrivals': 5,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [26, 59],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Estonia',
        'Country of asylum (ISO)': 'EST',
        'Resettlement arrivals': 19,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [26, 64],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Finland',
        'Country of asylum (ISO)': 'FIN',
        'Resettlement arrivals': 972,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [2, 46],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'France',
        'Country of asylum (ISO)': 'FRA',
        'Resettlement arrivals': 2279,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-2, 54],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum':
          'United Kingdom of Great Britain and Northern Ireland',
        'Country of asylum (ISO)': 'GBR',
        'Resettlement arrivals': 4850,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [9, 51],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Germany',
        'Country of asylum (ISO)': 'DEU',
        'Resettlement arrivals': 2830,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15.5, 45.1667],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Croatia',
        'Country of asylum (ISO)': 'HRV',
        'Resettlement arrivals': 40,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-18, 65],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Iceland',
        'Country of asylum (ISO)': 'ISL',
        'Resettlement arrivals': 48,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 53],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Ireland',
        'Country of asylum (ISO)': 'IRL',
        'Resettlement arrivals': 261,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [12.8333, 42.8333],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Italy',
        'Country of asylum (ISO)': 'ITA',
        'Resettlement arrivals': 832,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [24, 56],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Lithuania',
        'Country of asylum (ISO)': 'LTU',
        'Resettlement arrivals': 57,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [6.1667, 49.75],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Luxembourg',
        'Country of asylum (ISO)': 'LUX',
        'Resettlement arrivals': 177,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [25, 57],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Latvia',
        'Country of asylum (ISO)': 'LVA',
        'Resettlement arrivals': 40,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [5.75, 52.5],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Netherlands',
        'Country of asylum (ISO)': 'NLD',
        'Resettlement arrivals': 2183,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 62],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Norway',
        'Country of asylum (ISO)': 'NOR',
        'Resettlement arrivals': 2667,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [174, -41],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'New Zealand',
        'Country of asylum (ISO)': 'NZL',
        'Resettlement arrivals': 313,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 39.5],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Portugal',
        'Country of asylum (ISO)': 'PRT',
        'Resettlement arrivals': 30,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [25, 46],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Romania',
        'Country of asylum (ISO)': 'ROU',
        'Resettlement arrivals': 43,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-4, 40],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Spain',
        'Country of asylum (ISO)': 'ESP',
        'Resettlement arrivals': 1071,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 62],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Sweden',
        'Country of asylum (ISO)': 'SWE',
        'Resettlement arrivals': 1574,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [8, 47],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Switzerland',
        'Country of asylum (ISO)': 'CHE',
        'Resettlement arrivals': 541,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [35, 39],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Turkey',
        'Country of asylum (ISO)': 'TUR',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97, 38],
      },
      properties: {
        Year: 2017,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'United States of America',
        'Country of asylum (ISO)': 'USA',
        'Resettlement arrivals': 3024,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [133, -27],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Australia',
        'Country of asylum (ISO)': 'AUS',
        'Resettlement arrivals': 2181,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [4, 50.8333],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belgium',
        'Country of asylum (ISO)': 'BEL',
        'Resettlement arrivals': 800,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [25, 43],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Bulgaria',
        'Country of asylum (ISO)': 'BGR',
        'Resettlement arrivals': 20,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95, 60],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Canada',
        'Country of asylum (ISO)': 'CAN',
        'Resettlement arrivals': 10694,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [26, 59],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Estonia',
        'Country of asylum (ISO)': 'EST',
        'Resettlement arrivals': 18,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [26, 64],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Finland',
        'Country of asylum (ISO)': 'FIN',
        'Resettlement arrivals': 360,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [2, 46],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'France',
        'Country of asylum (ISO)': 'FRA',
        'Resettlement arrivals': 4495,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-2, 54],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum':
          'United Kingdom of Great Britain and Northern Ireland',
        'Country of asylum (ISO)': 'GBR',
        'Resettlement arrivals': 4387,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [9, 51],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Germany',
        'Country of asylum (ISO)': 'DEU',
        'Resettlement arrivals': 2929,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15.5, 45.1667],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Croatia',
        'Country of asylum (ISO)': 'HRV',
        'Resettlement arrivals': 110,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-18, 65],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Iceland',
        'Country of asylum (ISO)': 'ISL',
        'Resettlement arrivals': 15,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 53],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Ireland',
        'Country of asylum (ISO)': 'IRL',
        'Resettlement arrivals': 338,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [12.8333, 42.8333],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Italy',
        'Country of asylum (ISO)': 'ITA',
        'Resettlement arrivals': 309,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [24, 56],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Lithuania',
        'Country of asylum (ISO)': 'LTU',
        'Resettlement arrivals': 20,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [5.75, 52.5],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Netherlands',
        'Country of asylum (ISO)': 'NLD',
        'Resettlement arrivals': 814,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 62],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Norway',
        'Country of asylum (ISO)': 'NOR',
        'Resettlement arrivals': 1637,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [174, -41],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'New Zealand',
        'Country of asylum (ISO)': 'NZL',
        'Resettlement arrivals': 233,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 39.5],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Portugal',
        'Country of asylum (ISO)': 'PRT',
        'Resettlement arrivals': 20,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-4, 40],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Spain',
        'Country of asylum (ISO)': 'ESP',
        'Resettlement arrivals': 830,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [19.5, 48.6667],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Slovakia',
        'Country of asylum (ISO)': 'SVK',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 46],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Slovenia',
        'Country of asylum (ISO)': 'SVN',
        'Resettlement arrivals': 34,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 62],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Sweden',
        'Country of asylum (ISO)': 'SWE',
        'Resettlement arrivals': 2847,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [8, 47],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Switzerland',
        'Country of asylum (ISO)': 'CHE',
        'Resettlement arrivals': 994,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [35, 39],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Turkey',
        'Country of asylum (ISO)': 'TUR',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [32, 49],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Ukraine',
        'Country of asylum (ISO)': 'UKR',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97, 38],
      },
      properties: {
        Year: 2018,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'United States of America',
        'Country of asylum (ISO)': 'USA',
        'Resettlement arrivals': 41,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [133, -27],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Australia',
        'Country of asylum (ISO)': 'AUS',
        'Resettlement arrivals': 1626,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [4, 50.8333],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belgium',
        'Country of asylum (ISO)': 'BEL',
        'Resettlement arrivals': 239,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [25, 43],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Bulgaria',
        'Country of asylum (ISO)': 'BGR',
        'Resettlement arrivals': 55,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95, 60],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Canada',
        'Country of asylum (ISO)': 'CAN',
        'Resettlement arrivals': 8930,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [26, 64],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Finland',
        'Country of asylum (ISO)': 'FIN',
        'Resettlement arrivals': 444,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [2, 46],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'France',
        'Country of asylum (ISO)': 'FRA',
        'Resettlement arrivals': 2735,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-2, 54],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum':
          'United Kingdom of Great Britain and Northern Ireland',
        'Country of asylum (ISO)': 'GBR',
        'Resettlement arrivals': 4341,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [9, 51],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Germany',
        'Country of asylum (ISO)': 'DEU',
        'Resettlement arrivals': 4011,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15.5, 45.1667],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Croatia',
        'Country of asylum (ISO)': 'HRV',
        'Resettlement arrivals': 98,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-18, 65],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Iceland',
        'Country of asylum (ISO)': 'ISL',
        'Resettlement arrivals': 49,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 53],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Ireland',
        'Country of asylum (ISO)': 'IRL',
        'Resettlement arrivals': 764,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [12.8333, 42.8333],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Italy',
        'Country of asylum (ISO)': 'ITA',
        'Resettlement arrivals': 373,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29, 47],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Rep. of Moldova',
        'Country of asylum (ISO)': 'MDA',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [5.75, 52.5],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Netherlands',
        'Country of asylum (ISO)': 'NLD',
        'Resettlement arrivals': 1350,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 62],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Norway',
        'Country of asylum (ISO)': 'NOR',
        'Resettlement arrivals': 820,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [174, -41],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'New Zealand',
        'Country of asylum (ISO)': 'NZL',
        'Resettlement arrivals': 103,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 39.5],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Portugal',
        'Country of asylum (ISO)': 'PRT',
        'Resettlement arrivals': 170,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [25, 46],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Romania',
        'Country of asylum (ISO)': 'ROU',
        'Resettlement arrivals': 74,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-4, 40],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Spain',
        'Country of asylum (ISO)': 'ESP',
        'Resettlement arrivals': 744,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 62],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Sweden',
        'Country of asylum (ISO)': 'SWE',
        'Resettlement arrivals': 2161,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [8, 47],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Switzerland',
        'Country of asylum (ISO)': 'CHE',
        'Resettlement arrivals': 968,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [32, 49],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Ukraine',
        'Country of asylum (ISO)': 'UKR',
        'Resettlement arrivals': 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97, 38],
      },
      properties: {
        Year: 2019,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'United States of America',
        'Country of asylum (ISO)': 'USA',
        'Resettlement arrivals': 631,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [133, -27],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Australia',
        'Country of asylum (ISO)': 'AUS',
        'Resettlement arrivals': 178,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-95, 60],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Canada',
        'Country of asylum (ISO)': 'CAN',
        'Resettlement arrivals': 1962,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 56],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Denmark',
        'Country of asylum (ISO)': 'DNK',
        'Resettlement arrivals': 31,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [26, 64],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Finland',
        'Country of asylum (ISO)': 'FIN',
        'Resettlement arrivals': 292,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [2, 46],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'France',
        'Country of asylum (ISO)': 'FRA',
        'Resettlement arrivals': 810,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-2, 54],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum':
          'United Kingdom of Great Britain and Northern Ireland',
        'Country of asylum (ISO)': 'GBR',
        'Resettlement arrivals': 662,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [9, 51],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Germany',
        'Country of asylum (ISO)': 'DEU',
        'Resettlement arrivals': 1366,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [12.8333, 42.8333],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Italy',
        'Country of asylum (ISO)': 'ITA',
        'Resettlement arrivals': 3,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [5.75, 52.5],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Netherlands',
        'Country of asylum (ISO)': 'NLD',
        'Resettlement arrivals': 274,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [10, 62],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Norway',
        'Country of asylum (ISO)': 'NOR',
        'Resettlement arrivals': 992,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [174, -41],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'New Zealand',
        'Country of asylum (ISO)': 'NZL',
        'Resettlement arrivals': 60,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 39.5],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Portugal',
        'Country of asylum (ISO)': 'PRT',
        'Resettlement arrivals': 111,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-4, 40],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Spain',
        'Country of asylum (ISO)': 'ESP',
        'Resettlement arrivals': 354,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [15, 62],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Sweden',
        'Country of asylum (ISO)': 'SWE',
        'Resettlement arrivals': 1024,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [8, 47],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Switzerland',
        'Country of asylum (ISO)': 'CHE',
        'Resettlement arrivals': 482,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-97, 38],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'United States of America',
        'Country of asylum (ISO)': 'USA',
        'Resettlement arrivals': 365,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-8, 53],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Ireland',
        'Country of asylum (ISO)': 'IRL',
        'Resettlement arrivals': 194,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [4, 50.8333],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Belgium',
        'Country of asylum (ISO)': 'BEL',
        'Resettlement arrivals': 176,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-64, -34],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Argentina',
        'Country of asylum (ISO)': 'ARG',
        'Resettlement arrivals': 4,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [25, 46],
      },
      properties: {
        Year: 2020,
        'Country of origin': 'Syrian Arab Rep.',
        'Country of origin (ISO)': 'SYR',
        'Country of asylum': 'Romania',
        'Country of asylum (ISO)': 'ROU',
        'Resettlement arrivals': 37,
      },
    },
  ],
}
export default data
