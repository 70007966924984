import React, { useState, useEffect, useRef } from 'react'

import * as d3 from 'd3-array'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import ReactMapGL, { NavigationControl, Source, Layer } from 'react-map-gl'

import mapboxgl from "!mapbox-gl"
import 'mapbox-gl/dist/mapbox-gl.css'

import 'styles/animations.css'
import 'mapbox-gl/dist/mapbox-gl.css'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const ScrollySection = styled.section`
  max-width: 100vw;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.lightGrey};

  figure.sticky {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    height: ${(props) => props.height || '100vh'};
    min-height: -webkit-fill-available;
    background: transparent;
    overflow: hidden;
    margin: 0;
    top: 0;
    background: white !important;
    left: 0;
    z-index: 1 !important;

    .mapboxgl-ctrl.mapboxgl-ctrl-group {
      top: 10rem;
      right: 2.5rem;
      position: absolute;
    }

    .overlay-photo {
      width: 100%;
      height: 100%;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 10;

      &.fade-in {
        transition: all 1s;
      }
    }

    .mapboxgl-map {
      margin-top: 8rem;
      z-index: 1 !important;

      @media screen and (max-width: 500px) {
        margin-top: 6rem;
      }
    }
  }

  article {
    padding: 0;
    width: 90%;
    margin: 0 auto;
    position: -webkit-sticky;
    position: sticky;
    z-index: 999;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    max-width: 90rem;
    margin: 0 auto;
    pointer-events: none;

    .step {
      min-height: 135vh;
      margin-bottom: 50vh;
      margin-top: 50vh;
      max-width: 70rem;
      margin-left: auto;
      margin-right: auto;
      pointer-events: none;

      &.image {
        // min-height: 200vh;
      }

      > * {
        border-radius: 1px;
        pointer-events: none;
      }

      > p {
        border: 2px solid white;
      }

      > div {
        -webkit-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.3);
      }

      > p {
        -webkit-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.3);
      }

      a {
        font-weight: 500;
        text-decoration: none;
        pointer-events: auto;
        color: #9f3e52;

        &:hover {
          text-decoration: #9f3e52 underline;
        }
      }

      h1 {
        font-family: ${(props) => props.theme.fonts.serif};
        color: ${(props) => props.theme.colors.burgundy};
        background-color: ${(props) => props.theme.colors.white};
        text-align: left;
        padding: 1rem;
        margin: 0;
      }

      p {
        margin: 0;
        padding: 1rem;
        text-align: left;
        font-weight: 400;
        font-family: ${(props) => props.theme.fonts.sans};
        background-color: ${(props) => props.theme.colors.white};
        -webkit-transition: background-color 250ms ease-in-out;
        transition: background-color 250ms ease-in-out;
        color: ${(props) => props.theme.colors.text};
      }
    }
  }

  max-width: 100vw;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.lightGrey};

  figure.sticky {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    height: ${(props) => props.height || '100vh'};
    background: transparent;
    overflow: hidden;
    margin: 0;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const DataButton = styled.div`
  display: inline-block;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: white;

  &.inactive {
    opacity: 0.5;
  }

  &.burgundy {
    color: #9f3e52;
  }

  &.blue {
    color: #413c78;
  }
`

const BottomWrapper = styled.div`
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 999;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 1);

  * {
    font-size: 1.5rem;
  }
`

const CallToAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  position: absolute;
  width: auto;
  min-width: 16.5rem;
  text-align: center;
  height: auto;
  z-index: 999;
  padding: 0.5rem;
  border-radius: 5px;
  left: 2rem;
  top: -125%;
  opacity: 0.6;
  cursor: pointer;

  &.bottom {
    left: 40%;
    top: -130%;
    min-width: 10rem;

    @media screen and (max-width: 500px) {
      top: -105%;
    }
  }

  -webkit-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.75);

  .tri-right.border.btm-left-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 30px;
    right: auto;
    top: auto;
    bottom: -40px;
    border: 20px solid;
    border-color: #666 transparent transparent #666;
  }

  &.btm-left-in:after {
    pointer-events: none;
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 38px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 12px solid;
    border-color: white transparent transparent white;
  }

  span {
    height: 100%;
    display: flex;
    font-size: 1.4rem;
  }
`

const StyledArticle = styled.article`
  transition: opacity 0.5s;
  pointer-events: none;
  will-change: opacity;
`

const InteractionToggle = styled.div`
  position: absolute;
  left: 0;
  top: calc(50% - 2.5rem);

  .ui.button {
    background-color: #9f3e52;
    color: ${(props) => props.theme.colors.white};
    border-radius: none;
    font-family: ${(props) => props.theme.fonts.sans};
  }
`

const ToggleButton = styled.button`
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  width: 10rem;
  height: 5rem;
  text-align: center;
  z-index: 999;
  padding: 1rem;
  background: rgba(255, 255, 255, 1);
  transition: opacity 1s;

  -webkit-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.5);

  &.active {
    // opacity: 0.7;
  }
`

const TooltipBox = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: calc(50% - 4rem);
  left: 100%;
  width: 15rem;
  height: 8rem;
  text-align: center;
  z-index: 999;
  padding: 1rem;
  background: rgba(255, 255, 255, 1);
  transition: opacity 1s;

  -webkit-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.5);

  p {
    font-size: 1.5rem;
    line-height: 1.25;

    .highlighted {
      font-weight: bold;

      &.burgundy {
        color: #9f3e52;
      }
      &.blue {
        color: #413c78;
      }
    }
  }
`

const ScrollMap = (props) => {
  const [mapIsActive, setMapIsActive] = useState(false)
  const [viewport, setViewport] = useState({ ...props.location })
  const [dataRefugeesExtent, setDataRefugeesExtent] = useState([0, 4000000])
  const [dataResettledExtent, setDataResettledExtent] = useState([0, 4000000])
  const [refugeeTooltipData, setRefugeeTooltipData] = useState()
  const [resettledTooltipData, setResettledTooltipData] = useState()
  const [showRefugees, setShowRefugees] = useState(true)
  const [showResettled, setShowResettled] = useState(true)
  const [userClickedExploreButton, setUserClickedExploreButton] =
    useState(false)
  const [userClickedDataButton, setUserClickedDataButton] = useState(false)

  const _onViewportChange = (viewport) => {
    setViewport({ ...viewport })
  }

  let mapRef = useRef()

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const replaceLongCountryNames = (country) => {
    if (country === 'United Kingdom of Great Britain and Northern Ireland') {
      return 'UK & N. Ireland'
    }
    if (country === 'Serbia and Kosovo: S/RES/1244 (1999)') {
      return 'Serbia & Kosovo'
    }
    if (country === 'United States of America') {
      return 'USA'
    }
    return country
  }

  useEffect(() => {
    const newViewport = {
      ...viewport,
      longitude: props.location.lng,
      latitude: props.location.lat,
      zoom: props.location.zoom,
    }
    setViewport(newViewport)
    // eslint-disable-next-line
  }, [props.location])

  useEffect(() => {
    // Set min/max extents based on new data
    const maxRefugees = props.refugeesData.features.map(
      (el) => el.properties.refugees
    )
    const extent = d3.extent(maxRefugees)
    setDataRefugeesExtent(extent)
    const maxResettled = props.refugeesResettledData.features.map(
      (el) => el.properties['Resettlement arrivals']
    )
    const extentResettled = d3.extent(maxResettled)
    setDataResettledExtent(extentResettled)
  }, [props.refugeesData, props.refugeesResettledData, setDataRefugeesExtent])

  const handleMouseMove = (e) => {
    const map = mapRef.current.getMap()
    const features = map.queryRenderedFeatures(e.point)

    const filteredFeaturesByRefugees = features.filter(
      (feature) => feature.source === 'refugees'
    )

    if (filteredFeaturesByRefugees.length > 0) {
      const selectedFeature = filteredFeaturesByRefugees[0]
      const amountOfRefugees = selectedFeature.properties.refugees
      const currentYear = selectedFeature.properties.Year
      const countryOfAsylum = selectedFeature.properties['Country of asylum']
      const refugeesTooltipData = {
        amountOfRefugees,
        currentYear,
        countryOfAsylum,
      }
      setRefugeeTooltipData(refugeesTooltipData)
    } else {
      setRefugeeTooltipData(null)
    }

    const filteredFeaturesByResettled = features.filter(
      (feature) => feature.source === 'refugeesResettled'
    )

    if (filteredFeaturesByResettled.length > 0) {
      const selectedFeature = filteredFeaturesByResettled[0]
      console.log(selectedFeature)
      const amountOfResettled =
        selectedFeature.properties['Resettlement arrivals']
      const currentYear = selectedFeature.properties.Year
      const countryOfAsylum = selectedFeature.properties['Country of asylum']
      const resettledTooltipData = {
        amountOfResettled,
        currentYear,
        countryOfAsylum,
      }
      setResettledTooltipData(resettledTooltipData)
    } else {
      setResettledTooltipData(null)
    }
  }

  return (
    <ScrollySection
      style={{
        ...props.style,
      }}
    >
      <figure className={'sticky'}>
        {props.showOverlayPhoto && (
          <div className={'overlay-photo fade-in'}>
            <GatsbyImage
              style={{ height: '100%', width: '100%' }}
              // imageStyle={{ height: '100%', width: '100%' }}
              image={props.showOverlayPhoto.image}
              alt={'An image.'}
            />
          </div>
        )}
        <div style={{ pointerEvents: mapIsActive ? 'auto' : 'none' }}>
          <ReactMapGL
            {...viewport}
            width='100vw'
            style={{ opacity: props.showOverlayPhoto ? 0 : 1 }}
            className={mapIsActive ? 'active' : ''}
            ref={mapRef}
            height={props.height || '100vh'}
            onViewportChange={(nextViewport) => _onViewportChange(nextViewport)}
            mapStyle={'mapbox://styles/mrcfhr/ckmdchoarlp4f17p6glzj4un2'}
            // onClick={handleMouseClick}
            onMouseMove={handleMouseMove}
            transitionDuration={mapIsActive ? 0 : props.transition.duration}
            // transitionEasing={d3.easeLinear}
            // transitionInterpolator={
            //   mapIsActive ? null : new FlyToInterpolator({})
            // }
            mapboxApiAccessToken={
              'pk.eyJ1IjoibXJjZmhyIiwiYSI6ImNqeWx2eWx3MDBicm0zY3J0YnRla25yN2UifQ.CKfTqZTXfSZie6mJHjU0OQ'
            }
          >
            <Source
              id='refugees'
              type='geojson'
              data={props.refugeesData}
              generateId
            />
            {showRefugees && (
              <Layer
                id='refugees-bubbles'
                type='circle'
                source='refugees'
                paint={{
                  'circle-opacity': {
                    property: 'refugees',
                    stops: [
                      [0, 0],
                      [1, showResettled && showRefugees ? 0.7 : 0],
                      [
                        dataRefugeesExtent[1],
                        props.showRefugeesData && showRefugees ? 0.5 : 0,
                      ],
                    ],
                  },
                  'circle-radius': {
                    property: 'refugees',
                    // stops: customMapStops,
                    stops: [
                      [0, 0],
                      [1, 5],
                      [dataRefugeesExtent[1] / 6, 8],
                      [dataRefugeesExtent[1] / 5, 10],
                      [dataRefugeesExtent[1] / 4, 20],
                      [dataRefugeesExtent[1] / 2, 45],
                      [dataRefugeesExtent[1], 65],
                    ],
                  },
                  'circle-color': '#9f3e52',
                }}
              />
            )}
            <Source
              id='refugeesResettled'
              type='geojson'
              data={props.refugeesResettledData}
              generateId={true}
              // InteractionToggle={(e) => console.log(e))}
            />
            {showResettled && (
              <Layer
                id='refugees-resettled-bubbles'
                type='circle'
                source='refugeesResettled'
                paint={{
                  'circle-opacity': {
                    property: 'Resettlement arrivals',
                    stops: [
                      [0, 0],
                      [1, showRefugees && showResettled ? 1 : 0.7],
                      [dataResettledExtent[1], showResettled ? 0.5 : 0],
                    ],
                  },
                  'circle-color': '#413c78',
                  'circle-radius': {
                    property: 'Resettlement arrivals',
                    stops: [
                      [0, 0],
                      [1, 5],
                      [
                        (!showRefugees && showResettled
                          ? dataResettledExtent[1]
                          : dataRefugeesExtent[1]) / 6,
                        8,
                      ],
                      [
                        (!showRefugees && showResettled
                          ? dataResettledExtent[1]
                          : dataRefugeesExtent[1]) / 5,
                        10,
                      ],
                      [
                        (!showRefugees && showResettled
                          ? dataResettledExtent[1]
                          : dataRefugeesExtent[1]) / 4,
                        20,
                      ],
                      [
                        (!showRefugees && showResettled
                          ? dataResettledExtent[1]
                          : dataRefugeesExtent[1]) / 2,
                        45,
                      ],
                      [
                        !showRefugees && showResettled
                          ? dataResettledExtent[1]
                          : dataRefugeesExtent[1],
                        65,
                      ],
                    ],
                  },
                }}
              />
            )}
            {mapIsActive && (
              <NavigationControl
                showCompass={false}
                style={{
                  right: 10,
                  top: 10,
                  position: 'absolute',
                  zIndex: 9999,
                }}
              />
            )}
          </ReactMapGL>
        </div>
        {!props.showOverlayPhoto && (
          <InteractionToggle
            style={{
              maxWidth: '8rem',
              textAlign: 'right',
              pointerEvents: 'auto',
              zIndex: 9999,
            }}
            onClick={() => {
              setMapIsActive(!mapIsActive)
              setUserClickedExploreButton(true)
            }}
          >
            <ToggleButton
              className={`ui big icon button shake-left ${
                mapIsActive ? 'active' : 'bounce-left inactive'
              }`}
            >
              {mapIsActive ? (
                <span style={{ textAlign: 'left' }}>
                  Continue reading
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='1.3rem'
                    height='1.3rem'
                    viewBox='0 0 100 100'
                    fill={'white'}
                    style={{ display: 'inline', marginLeft: '1rem' }}
                  >
                    <path d='M72.082 53.332L54.168 71.25V12.5h-8.336v58.75L27.918 53.332l-5.836 5.836L50 87.082l27.918-27.914z'></path>
                  </svg>
                </span>
              ) : (
                <span style={{ textAlign: 'left' }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='1.5rem'
                    height='1.5rem'
                    viewBox='0 0 100 100'
                    fill={'white'}
                    style={{ display: 'inline', marginRight: '1rem' }}
                  >
                    <path d='M55.184 51.027l17.484 17.48a2.942 2.942 0 01-4.16 4.16l-17.48-17.484-5.664 13.594c-1.02 2.442-4.489 2.406-5.453-.054L23.735 27.547c-.942-2.391 1.422-4.754 3.812-3.813L68.723 39.91c2.461.965 2.496 4.434.055 5.453zM2.942 5.882a2.94 2.94 0 110-5.883h8.824a2.94 2.94 0 110 5.883zm23.527 0c-1.621 0-2.938-1.316-2.938-2.941S24.849-.001 26.47-.001h8.824a2.94 2.94 0 110 5.883zm23.531 0a2.94 2.94 0 110-5.883h8.824a2.94 2.94 0 110 5.883zm23.531 0a2.94 2.94 0 110-5.883h8.82a2.94 2.94 0 110 5.883zm20.586-2.941a2.94 2.94 0 115.883 0v8.824a2.94 2.94 0 11-5.883 0zm0 23.527c0-1.622 1.316-2.938 2.941-2.938S100 24.846 100 26.468v8.824a2.94 2.94 0 11-5.883 0zm0 23.53a2.94 2.94 0 115.883 0v8.825a2.94 2.94 0 11-5.883 0zm0 23.532a2.94 2.94 0 115.883 0v8.82a2.94 2.94 0 11-5.883 0zm2.941 20.586a2.94 2.94 0 110 5.882h-8.824a2.94 2.94 0 110-5.882zm-23.527 0c1.621 0 2.938 1.316 2.938 2.941s-1.316 2.941-2.938 2.941h-8.824a2.94 2.94 0 110-5.882zm-23.53 0a2.94 2.94 0 110 5.882h-8.825a2.94 2.94 0 110-5.882zm-23.532 0a2.94 2.94 0 110 5.882h-8.82a2.94 2.94 0 110-5.882zM5.883 97.057a2.94 2.94 0 11-5.882 0v-8.824a2.94 2.94 0 115.882 0zm0-23.527c0 1.621-1.316 2.937-2.941 2.937S.001 75.151.001 73.53v-8.824a2.94 2.94 0 115.882 0zm0-23.531a2.94 2.94 0 11-5.882 0v-8.824a2.94 2.94 0 115.882 0zm0-23.531a2.94 2.94 0 11-5.882 0v-8.82a2.94 2.94 0 115.882 0z'></path>
                  </svg>
                  Explore the map
                </span>
              )}
            </ToggleButton>
            {!userClickedExploreButton && (
              <CallToAction
                onClick={() => setUserClickedExploreButton(true)}
                className={'heartbeat btm-left-in'}
              >
                <span>
                  Click here to pause the storyline and explore the numbers on
                  the map...
                </span>
              </CallToAction>
            )}
          </InteractionToggle>
        )}
      </figure>
      <StyledArticle
        style={{
          opacity: mapIsActive ? 0 : 1,
        }}
      >
        {props.children}
      </StyledArticle>
      {props.showOverlayPhoto ? (
        <BottomWrapper>
          <p>
            <strong>{props.year} | </strong>
            <span>{props.showOverlayPhoto.caption}</span>
          </p>
        </BottomWrapper>
      ) : (
        <BottomWrapper>
          {!userClickedDataButton && (
            <CallToAction
              onClick={() => setUserClickedDataButton(true)}
              className={'bottom heartbeat btm-left-in'}
            >
              <span>
                Click here to
                <br />
                switch views
              </span>
            </CallToAction>
          )}
          <strong style={{ paddingLeft: '1rem' }}>{props.year}</strong>
          <DataButton
            className={`burgundy ${
              showRefugees ? 'active bounce-top' : 'inactive'
            }`}
            onClick={() => {
              setShowRefugees(!showRefugees)
              setUserClickedDataButton(true)
            }}
          >
            ⬤ <strong>Syrian refugees</strong> per country
          </DataButton>
          <DataButton
            className={`blue ${
              showResettled ? 'active bounce-top' : 'inactive'
            }`}
            onClick={() => {
              setShowResettled(!showResettled)
              setUserClickedDataButton(true)
            }}
          >
            ⬤ <strong>Resettled</strong> Syrian refugees
          </DataButton>
        </BottomWrapper>
      )}
      {mapIsActive && (resettledTooltipData || refugeeTooltipData) && (
        <TooltipBox>
          {refugeeTooltipData && resettledTooltipData && (
            <strong>
              {replaceLongCountryNames(refugeeTooltipData.countryOfAsylum)}
            </strong>
          )}
          {refugeeTooltipData && !resettledTooltipData && (
            <strong>
              {replaceLongCountryNames(refugeeTooltipData.countryOfAsylum)}
            </strong>
          )}
          {resettledTooltipData && !refugeeTooltipData && (
            <strong>
              {replaceLongCountryNames(resettledTooltipData.countryOfAsylum)}
            </strong>
          )}
          {refugeeTooltipData && (
            <p>
              <span className={'highlighted burgundy'}>
                {numberWithCommas(refugeeTooltipData.amountOfRefugees)} Syrians
              </span>
            </p>
          )}
          {resettledTooltipData && (
            <p>
              <span className={'highlighted blue'}>
                {numberWithCommas(resettledTooltipData.amountOfResettled)}{' '}
                Resettled
              </span>
            </p>
          )}
        </TooltipBox>
      )}
    </ScrollySection>
  )
}

export default ScrollMap
