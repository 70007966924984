import React, { useState } from 'react'
import styled from 'styled-components'

import '@styles/animations.css'

const CollapsibleWrapper = styled.div`
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #282828;
  margin-bottom: 3rem;

  &:not(.is-open):hover {
    background: #ddd;
  }

  .title {
    cursor: pointer;

    &:focus {
      outline: none;
    }

    p {
      margin-bottom: 0;
      &:focus {
        outline: none;
      }
    }
  }

  .content {
    p:last-child {
      margin-bottom: 0;
    }
    .ui.basic.table tbody tr {
      border-bottom: none !important;
      box-shadow: none !important;
    }
  }
`

const CollapsibleComponent = (props) => {
  const { children } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <CollapsibleWrapper className={isOpen ? 'is-open' : ''}>
      <div
        className={'title'}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={() => {}}
        role={'tree'}
        tabIndex={0}
      >
        <p>
          {props.icon && (
            <i
              style={{ marginRight: '1rem' }}
              className={`icon ${props.icon}`}
            />
          )}
          <strong>{props.title}</strong>{' '}
          <i className={`icon chevron ${isOpen ? 'down' : 'right'}`} />
        </p>
      </div>
      {isOpen && <div className={'content'}>{children}</div>}
    </CollapsibleWrapper>
  )
}

export default CollapsibleComponent
