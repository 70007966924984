import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'
import Helmet from 'react-helmet'
import gjfilter from 'geojson-filter'
import { GatsbyImage } from 'gatsby-plugin-image'

/* Importing custom components from main dir */
import Layout from 'components/common/layout'
import ScrollMap from 'components/scrollytelling/map/scrollMapSyria'
import HeaderComponent from 'components/scrollytelling/header'
import ContainerComponent from 'components/scrollytelling/container'
import TextContainerComponent from 'components/scrollytelling/text'
import CollapsibleComponent from 'components/scrollytelling/collapsible'

import refugeesData from 'data/syria-story/refugees-geojson.js'
import refugeesResettledData from 'data/syria-story/refugees-resettled-geojson.js'

import 'semantic-ui-css/components/table.min.css'

const ScrollToTop = styled.a`
	position: relative;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	z-index: 999;
	bottom: 0;

	&:hover {
		background: transparent;
	}

	button {
		position: relative;
		background-size: cover;
		background-image: url('https://www.thenewhumanitarian.org/sites/all/themes/thenewhumanitarian/images/icon-return.svg');
	}
`

const StyledTags = styled.ul`
	margin-left: 0;

	.meta-list__item {
		margin-top: 0.5rem;
	}
	.meta-list__item[itemProp='reportType'],
	.meta-list__item[itemProp='reportType'] a:hover {
		&:hover {
			background: transparent;
			color: black;
		}
	}
	.meta-list__item[itemProp='datePublished'] {
		&:hover {
			cursor: default;
		}
	}
	.meta-list__item:first-child:hover {
		opacity: 0.9;
	}
	.meta-list__item:first-child a:hover {
		color: white;
		opacity: 0.9;
	}
	@media screen and (max-width: 500px) {
		padding: 0;
		margin: 0 1rem;
	}
`

const SyriaYearContainer = styled.div`
	background: white !important;
	padding-bottom: 1rem;

	* {
		text-align: center !important;
	}
	.year {
		font-size: 1.8rem;
		font-family: 'Roboto', sans-serif !important;
		background: #9f3e52 !important;
		color: white !important;
		font-weight: bold !important;
	}
	.first-line {
		font-size: 1.8rem;
		line-height: 1.4;
		padding-top: 2rem !important;
		font-weight: bold;
		strong {
			border-bottom: 2px solid #9f3e52;
			color: #9f3e52;
		}
	}
	.second-line {
		font-size: 1.5rem;
		padding-top: 2rem !important;
		font-weight: bold;
		strong {
			border-bottom: 1px solid #413c78;
			color: #413c78;
		}
	}
`

const ReadMore = styled.div`
	text-align: center;
	background: white;
	padding: 2rem;
	line-height: 1.5;
`

const SyriaStory = (props) => {
	const coordinates = {
		lesvos: {
			lat: 39.26952212004341,
			lng: 26.278632174988584,
			zoom: 5,
		},
		start: {
			lat: 36.12910318259179,
			lng: 34.14244555871172,
			zoom: 4,
		},
		usa: {
			lat: 39.961245468972855,
			lng: -101.32443818133316,
			zoom: 2,
		},
		romaniaAndTurkey: {
			lat: 42.185503623368824,
			lng: 29.877116333615607,
			zoom: 4,
		},
		brazil: {
			lat: -9.834456612194193,
			lng: -51.5446473643149,
			zoom: 5,
		},
		mauritania: {
			lat: 20.67088484988922,
			lng: -10.747130864323717,
			zoom: 5,
		},
		europeAndTurkey: {
			lat: 45.72706011693274,
			lng: 20.36123974816387,
			zoom: 3,
		},
		italyAndGreece: {
			lat: 41.35154807745581,
			lng: 17.091111657628158,
			zoom: 5,
		},
		germany: {
			lat: 51,
			lng: 9,
			zoom: 5,
		},
		lebanon: {
			lat: 35.862285,
			lng: 33.854721,
			zoom: 5,
		},
		turkey: {
			lat: 39.326495175656696,
			lng: 35.91587201139186,
			zoom: 5,
		},
		jordan: {
			lat: 31.5207606645381,
			lng: 36.38414728524674,
			zoom: 5,
		},
		sudan: {
			lat: 16.26795069706926,
			lng: 30.263732375603418,
			zoom: 5,
		},
		center: {
			lat: 30.0,
			lng: 31.0,
			zoom: 2,
		},
	}

	const initialFilter = ['==', 'Year', 2010]

	// eslint-disable-next-line
	const [mapTransition, setMapTransition] = useState({ duration: 1000 })
	const [mapPosition, setMapPosition] = useState({
		...coordinates.start,
		zoom: 5,
	})
	const [currentYear, setCurrentYear] = useState(2011)
	const [filteredRefugeesData, setfilteredRefugeesData] = useState(gjfilter(refugeesData, initialFilter))
	const [filteredRefugeesResettledData, setfilteredRefugeesResettledData] = useState(gjfilter(refugeesResettledData, initialFilter))
	const [showRefugeesData, setShowRefugeesData] = useState(true)
	const [showRefugeesResettledData, setShowRefugeesResettledData] = useState(true)
	const [showOverlayPhoto, setShowOverlayPhoto] = useState(null)

	const filterData = (year) => {
		setCurrentYear(year)
		// Set options for the geojson filtering
		const refugeesFilter = ['==', 'Year', year]
		// Filter the refugees data (layer 1)
		const refugeesDataFilteredByYear = gjfilter(refugeesData, refugeesFilter)
		setfilteredRefugeesData(refugeesDataFilteredByYear)
		// Filter the refugees "resettled" data (layer 2)
		const refugeesResettledDataFilteredByYear = gjfilter(refugeesResettledData, refugeesFilter)
		setfilteredRefugeesResettledData(refugeesResettledDataFilteredByYear)
	}

	const Images = props.data.allFile.edges

	// console.log(Images)

	const getImageByName = (name) => {
		return Images.filter((el) => el.node.name === name)[0].node.childImageSharp.gatsbyImageData
	}

	return (
		<Layout
			shareProps={{
				title: 'The New Humanitarian | Ten years, 13 million lives uprooted',
				url: 'https://www.thenewhumanitarian.org/2021/11/17/Mediterranean-migration-Europe-system-abuse',
				socialTitle: 'Syrian refugees now live in 120 countries.',
				socialDescription: 'Over 10 years of war, millions of Syrians have been forced to flee their homes. Most remain in limbo.',
				socialImage: 'https://assets.thenewhumanitarian.org/s3fs-public/styles/responsive_large/public/syria-data-displaced-refugees.jpg.webp?itok=Rfd7f6aw',
				nodeId: 262214,
				updatedTime: '2021-11-17T15:44:58+00:00',
				modiefiedTime: '2021-11-17T12:07:56+00:00',
				publishedTime: '2021-11-17T13:48:03+00:00',
			}}
		>
			<Helmet>
				<title>The New Humanitarian | Ten years, 13 million lives uprooted</title>
				<meta property='og:site_name' content='The New Humanitarian' />
				<meta property='og:type' content='article' />
				<meta property='og:title' content='Syrian refugees now live in 120 countries.' />
				<meta property='og:url' content='https://www.thenewhumanitarian.org/special-report/2021/3/17/Syria-a-decade-of-flight' />
				<meta property='og:description' content='Over 10 years of war, millions of Syrians have been forced to flee their homes. Most remain in limbo.' />
				<meta property='og:updated_time' content='2021-03-17T10:02:00+00:00' />
				<meta
					property='og:image:url'
					content='https://assets.thenewhumanitarian.org/s3fs-public/styles/responsive_large/public/syria-data-displaced-refugees.jpg.webp?itok=Rfd7f6aw'
				/>
				<meta
					property='og:image:secure_url'
					content='https://assets.thenewhumanitarian.org/s3fs-public/styles/responsive_large/public/syria-data-displaced-refugees.jpg.webp?itok=Rfd7f6aw'
				/>
				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:title' content='Syrian refugees now live in 120 countries.' />
				<meta name='twitter:url' content='https://www.thenewhumanitarian.org/special-report/2021/3/17/Syria-a-decade-of-flight' />
				<meta name='twitter:description' content='Over 10 years of war, millions of Syrians have been forced to flee their homes. Most remain in limbo.' />
				<meta
					name='twitter:image:src'
					content='https://assets.thenewhumanitarian.org/s3fs-public/styles/responsive_large/public/syria-data-displaced-refugees.jpg.webp?itok=Rfd7f6aw'
				/>
				<meta property='article:published_time' content='2021-03-17T09:58:36+00:00' />
				<meta property='article:modified_time' content='2021-03-17T10:02:00+00:00' />
				<meta itemProp='name' content='Syria: A decade of flight' />
				<meta itemProp='description' content='Over 10 years of war, millions of Syrians have been forced to flee their homes. Most remain in limbo.' />
			</Helmet>

			<a href='#start'>
				<HeaderComponent image={getImageByName('HDA_irin_58')} title='' />
			</a>

			<ContainerComponent style={{ paddingBottom: 0, maxWidth: '900px', margin: '0 auto' }}>
				<div style={{ height: 0, marginTop: '-23rem', paddingTop: '5rem' }} id='start'>
					&nbsp;
				</div>
				<TextContainerComponent>
					<StyledTags className='meta-list' style={{ padding: '0 0 1rem ' }}>
						<li className='meta-list__item'>
							<a
								href='https://www.thenewhumanitarian.org/conflict'
								title='View more reports on Conflict'
								target={'_blank'}
								rel={'noreferrer'}
								className='meta-list__item--theme tab tab--report-theme'
								data-report-theme='Conflict'
							>
								Conflict
							</a>
						</li>
						<li className='meta-list__item' itemProp='reportType'>
							<a
								target='_blank'
								rel={'noopener noreferrer'}
								href='https://www.thenewhumanitarian.org/special-report'
								title='View more of our special reports'
								className='meta-list__item--type'
							>
								Special report
							</a>
						</li>
						<li className='meta-list__item' itemProp='datePublished' content='2021-03-17T12:00:00+01:00'>
							17 March 2021
						</li>
					</StyledTags>
					<h1 style={{ paddingTop: '1rem', paddingBottom: 0 }}>Syria: A decade of flight</h1>
					<h3 style={{ paddingTop: '1rem' }}>Over 10 years of war, millions of Syrians have been forced to flee their homes. Most remain in limbo.</h3>
					{/* <AuthorInfoComponent /> */}
				</TextContainerComponent>
			</ContainerComponent>

			<ContainerComponent
				style={{
					paddingBottom: 0,
					paddingTop: 0,
					maxWidth: '70rem',
					margin: '0 auto',
				}}
			>
				<TextContainerComponent>
					{/* <DateLine
            className='article__author-location'
            style={{
              // paddingTop: '2rem',
              textTransform: 'none',
            }}
          >
            DAMASCUS, Syria
          </DateLine> */}
					<div
						className={'content__bulk field-name-body'}
						style={{
							padding: 0,
						}}
					>
						<p>
							Over the past 10 years, at least 13 million Syrians have been forced to flee their homes by fear and violence, the ferocity and duration of which
							few predicted when protests against President Bashar al-Assad erupted in March 2011. They have run from imprisonment and forced conscription,
							bombs and ground fighting, chemical attacks and sieges. They have left behind homes, jobs, and loved ones, often risking death in doing so.
						</p>
						<p>
							Despite promises by the international community to support them, most of the 6.6 million Syrians registered as refugees remain in neighbouring
							countries like Turkey, Lebanon, and Jordan. Another 6.7 million Syrians are internally displaced, living in camps or sheltering wherever they can
							afford it inside the country. For the vast majority, with borders mostly closed, leaving the country is no longer an option.
						</p>
						<p>
							Since 2011, only around 201,000 Syrians have been relocated through the UN’s resettlement programme*, which enables so-called “third countries” to
							offer permanent residence to certain refugees, often those with exceptional needs. Numbers peaked in 2016, with Canada, the United States, and the
							UK the top destination countries. Soon after, however, they dropped off as countries began putting up literal and metaphorical fences to keep
							Syrians – as well as migrants and refugees from other countries – out.
						</p>
						<p>
							There have been some hopeful stories over the past 10 years, with individuals banding together to sponsor Syrian families in faraway countries,
							offering them financial and emotional support. Germany took in tens of thousands of Syrians through its “humanitarian admissions” programme until
							2015, and other states may yet offer a path to permanent citizenship for refugees they host. Some Syrians have managed to integrate into new
							communities, going to school, working, and learning new languages. But many others have little freedom of movement, restricted rights to work, and
							fear being thrown out at any moment.
						</p>
						<p>
							In recent years, the international community has tired of funding aid to refugees, while refugee poverty levels are rising and the hospitality of
							neighbouring countries is wearing thin. Consequently, pressure has been growing on those who have not been resettled to go back to Syria. Some
							have begun to come home, but Syria’s war is not fully over and the economy has also collapsed. For the vast majority, return remains a risky and
							unaffordable option.
						</p>
						<p>
							Here’s a look – in maps, pictures, and our own coverage – at why Syrians have been forced to flee, the journeys they’ve taken, and where they’ve
							ended up, over 10 years of war.
						</p>
						<CollapsibleComponent icon={'map'} title={'How to use this map'}>
							<hr style={{ borderTop: '1px dashed #282828', marginTop: '1rem' }} />
							<div className={'explainer-section'}>
								<p style={{ margin: '2rem 0 0 0' }}>
									<strong>Explore the map, and then go back to the story, scrolling down for the next section</strong>
								</p>
								<table className={'ui table very basic stackable^'}>
									<tbody>
										<tr>
											<td
												style={{
													width: '125px',
													verticalAlign: 'top',
													padding: '1rem',
												}}
											>
												<div>
													<GatsbyImage alt={'icon-explore-map'} style={{ maxWidth: '100px', margin: '0 auto' }} image={getImageByName('icon-explore-map')} />
												</div>
											</td>
											<td>
												<p>
													Click "Explore the map" <strong>to move around the map</strong>, and zoom in on individual countries. You can see numbers of refugees
													and resettled refugees in each country by hovering over its red or blue bubble. On mobile, tap the bubble. The size of the bubble
													corresponds to the relative number of refugees in that year.
												</p>
											</td>
										</tr>
										<tr>
											<td
												style={{
													width: '125px',
													verticalAlign: 'top',
													padding: '1rem',
												}}
											>
												<div>
													<GatsbyImage alt={'icon-continue-reading'} style={{ maxWidth: '100px', margin: '0 auto' }} image={getImageByName('icon-continue-reading')} />
												</div>
											</td>
											<td>
												<p>
													When you’re <strong>done exploring</strong> click “Continue reading”. You can then scroll down to the next section.
												</p>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<hr style={{ borderTop: '1px dashed #282828' }} />
							<div className={'explainer-section'}>
								<p style={{ margin: '2rem 0 0 0' }}>
									<strong>Switch between views</strong>
								</p>
								<table className={'ui table very basic stackable'}>
									<tbody>
										<tr>
											<td>
												<div>
													<GatsbyImage alt={'icon-both-datasets'} image={getImageByName('icon-both-datasets')} />
												</div>
											</td>
											<td>
												<p>
													When both the red and blue buttons are enabled, you will see:
													<br />
													<ul style={{ margin: 0, listStyle: 'none' }}>
														<li style={{ marginTop: '1rem' }}>
															<span style={{ color: '#9f3e52', fontWeight: 'bold' }}>Red bubbles</span> representing the number of Syrian refugees in each
															country. each country
														</li>
														<li>
															<span style={{ color: '#413c78', fontWeight: 'bold' }}>Blue bubbles</span>, usually much smaller, representing the number of
															Syrian refugees resettled in that country.
														</li>
													</ul>
												</p>
											</td>
										</tr>
										<tr>
											<td>
												<div>
													<GatsbyImage alt={''}  mage={getImageByName('icon-only-refugees')} alt={'icon-only-refugees'} />
												</div>
											</td>
											<td>
												<p>
													Click the red button to show or hide the <span style={{ color: '#9f3e52', fontWeight: 'bold' }}>red bubbles</span>.
												</p>
											</td>
										</tr>
										<tr>
											<td>
												<div>
													<GatsbyImage alt={''}  image={getImageByName('icon-only-resettled')} alt={'icon-only-resettled'} />
												</div>
											</td>
											<td>
												<p>
													Click the blue button to show or hide the <span style={{ color: '#413c78', fontWeight: 'bold' }}>blue bubbles</span>. If you choose to
													look at only the blue bubbles, they will become larger because the scale has changed. This setting is useful for{' '}
													<strong>comparing resettlement countries</strong>.
												</p>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</CollapsibleComponent>
					</div>
				</TextContainerComponent>
			</ContainerComponent>

			<ContainerComponent>
				<ScrollMap
					location={mapPosition}
					transition={mapTransition}
					refugeesData={filteredRefugeesData}
					showRefugeesData={showRefugeesData}
					refugeesResettledData={filteredRefugeesResettledData}
					showRefugeesResettledData={showRefugeesResettledData}
					showOverlayPhoto={showOverlayPhoto}
					year={currentYear}
				>
					{/* START 2011 */}
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition({ ...coordinates.start, zoom: 5 })
								filterData(2011)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<SyriaYearContainer>
							<h1 className={'year'}>2011</h1>
							<p className={'first-line'}>Syrians begin to flee their homes.</p>
						</SyriaYearContainer>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition({ ...coordinates.start, zoom: 4.8 })
								filterData(2011)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							Protests against the government of Bashar al-Assad are met with deadly violence, and unrest spreads across the country as members of the
							opposition take up arms.
						</p>
					</div>
					<div className='step image'>
						<Waypoint
							onEnter={() => {
								filterData(2011)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('syria-data-2011'),
									caption: 'A giant Syrian flag is held by the crowd during a 2011 protest against Syrian President Bashar al-Assad in Hama. (Ho New/REUTERS)',
								})
							}}
							onLeave={() => {
								setTimeout(() => {
									setShowOverlayPhoto(null)
								}, 800)
							}}
						/>
						<p>
							People begin to flee their homes in the thousands, taking refuge in safer parts of the country, or abroad: Turkey opens its first camps for Syrian
							refugees, who are able to cross back and forth between the two countries with relative ease.
						</p>
						<ReadMore>
							Read more →{' '}
							<a href='https://www.thenewhumanitarian.org/news/2012/03/21/refugees-say-it-becoming-harder-leave' target='_blank' rel={'noopener noreferrer'}>
								Refugees say it is becoming harder to leave
							</a>
						</ReadMore>
					</div>
					{/* START 2012 */}
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition({ ...coordinates.start, zoom: 4.7 })
								filterData(2012)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<SyriaYearContainer>
							<h1 className={'year'}>2012</h1>
							<p className={'first-line'}>
								<strong>729,000 Syrians</strong> are refugees. <br />2 million are internally displaced.
							</p>
							<p className={'second-line'}>
								<strong>195 Syrians</strong> are resettled in third countries.
							</p>
						</SyriaYearContainer>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition({ ...coordinates.center, zoom: 3 })
								filterData(2012)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							The Syrians who flee their homes mostly take shelter with friends, relatives, and even welcoming strangers across the border. But as the numbers
							increase and the UN’s refugee agency, UNHCR, scales up its capacity to register new arrivals, Jordan opens its first official camp for Syrian
							refugees, Za’atari.
						</p>
					</div>
					<div className='step image'>
						<Waypoint
							onEnter={() => {
								filterData(2012)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('201311011029560912'),
									caption: 'Tents in Jordan’s Za’atari camp, which was founded in 2012 and quickly swelled in population. (Andreas Hackl/TNH)',
								})
							}}
							onLeave={() => {
								setTimeout(() => {
									setShowOverlayPhoto(null)
								}, 800)
							}}
						/>
						<p>
							After just one year, Za’atari is already home to 120,000 people, and new arrivals are still streaming in. Our correspondent reports a night she
							spent in the camp: “Many [refugees] have spent days en route, trying to escape Syria. They include pregnant women and sick children. At the
							border, they are met by Jordanian soldiers, who board them onto buses to the camp. I watch as they unload their suitcases, some of them clearly
							exhausted.”
						</p>
						<ReadMore>
							Read more →{' '}
							<a href='https://www.thenewhumanitarian.org/feature/2013/06/20/overnight-jordan-s-za-atari-camp' target='_blank' rel={'noopener noreferrer'}>
								Overnight in Jordan’s Za’atari camp
							</a>
						</ReadMore>
					</div>
					{/* START 2013 */}
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.start)
								filterData(2013)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<SyriaYearContainer>
							<h1 className={'year'}>2013</h1>
							<p className={'first-line'}>
								<strong>2.5 million Syrians</strong> are refugees. <br />
								6.5 million are internally displaced.
							</p>
							<p className={'second-line'}>
								<strong>607 Syrians</strong> are resettled in third countries.
							</p>
						</SyriaYearContainer>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.germany)
								filterData(2013)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							In March 2013, Syria’s war passes the grim landmark of having produced one million refugees, including 400,000 who fled escalating violence in
							major cities like Aleppo and Damascus in the first three months of the year alone. Around half the refugees are children. Most are staying in
							Lebanon, Jordan, Turkey, Iraq, and Egypt. Germany begins a “humanitarian admissions” programme – which grants residency and some rights, but not
							citizenship, to 20,000 Syrians by 2015.
						</p>
					</div>
					<div className='step image'>
						<Waypoint
							onEnter={() => {
								filterData(2013)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('syria-data-2013_720'),
									caption:
										'A father reacts after two of his children are killed in Aleppo, allegedly due to shelling by forces loyal to the Syrian government. (Muzaffar Salman/REUTERS)',
								})
							}}
							onLeave={() => {
								setTimeout(() => {
									setShowOverlayPhoto(null)
								}, 800)
							}}
						/>
						<p>
							In a five-part diary, an anonymous writer tells of his decision to flee Damascus, life in exile in neighbouring Lebanon, opting to return home,
							and the horror of trying to contact friends while bombs rained down and a chemical attack hit not far away, in Douma. “I tried contacting friends
							living in the targeted towns,” he writes. “The cellular network was down, as it often is. On such nights, it’s normal to lose a friend or two. I
							was worried about each and every one of them. Unlike the news outlets, which tally casualty numbers, for us, these numbers are memories, parts of
							life and cells of the heart. I only managed to reach two of them within the next three days; I don’t know the fate of the others.”
						</p>
						<ReadMore>
							Read more →{' '}
							<a href='https://www.thenewhumanitarian.org/report/98862/syria-diary-limbo' target='_blank' rel={'noopener noreferrer'}>
								Syria diary: In Limbo
							</a>
						</ReadMore>
					</div>
					{/* START 2014 */}
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.lebanon)
								filterData(2014)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<SyriaYearContainer>
							<h1 className={'year'}>2014</h1>
							<p className={'first-line'}>
								<strong>3.9 million Syrians</strong> are refugees. <br />
								7.6 million are internally displaced.
							</p>
							<p className={'second-line'}>
								<strong>6,367 Syrians</strong> are resettled in third countries.
							</p>
						</SyriaYearContainer>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.lebanon)
								filterData(2014)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							The so-called Islamic State announces its self-proclaimed caliphate over parts of Iraq and Syria in 2014, and declares the Syrian city of Raqqa
							its capital. A US-led coalition begins airstrikes against the group the same year. More than 76,000 people die in 2014,{' '}
							<a
								href='https://www.nytimes.com/2015/01/02/world/middleeast/syrian-civil-war-2014-deadliest-so-far.html#:~:text=More%20than%2076%2C000%20people%20died,monitoring%20group%20reported%20on%20Thursday.&text=The%20rest%20include%20Syrian%20soldiers,that%20have%20joined%20the%20fighting.'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								according to one conflict monitor
							</a>
							, bringing the death toll of Syria’s war to over 200,000.
						</p>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.lebanon)
								filterData(2014)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							The number of registered Syrian refugees in Lebanon reaches one million. The Lebanese government does not allow official camps, so many refugees
							live in host cities or towns and informal “refugee settlements”. In 2015, when their numbers reach around 1.5 million, the government stops
							allowing the registration of refugees.
						</p>
					</div>
					<div className='step image'>
						<Waypoint
							onEnter={() => {
								filterData(2014)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('lebanon-syria-refugees'),
									caption: "An unregistered Syrian refugee family in an informal settlement in Lebanon's eastern Bekaa Valley (Diego Ibarra Sanchez/UNICEF)",
								})
							}}
							onLeave={() => {
								setTimeout(() => {
									setShowOverlayPhoto(null)
								}, 800)
							}}
						/>
						<p>
							The vast majority of Syrian refugees are in debt and struggle to get by on the limited aid they receive. Samira, originally from Idlib, explains
							how her seven-person family tries to make do with $105 in monthly aid and an additional monthly income of $250: “Everyone in the family has high
							blood pressure, and we need medicine all the time. But it's not covered by the UN, and I’ve tried to find organisations that can help us, but I
							don’t know where to go. I have a $3,000 debt with the pharmacist. It’s a terrible feeling: The feeling you owe someone for something so important
							for your survival.”
						</p>
						<ReadMore>
							Read more →{' '}
							<a href='https://www.thenewhumanitarian.org/photo-feature/2015/11/25/aid-syrians-how-far-does-it-go' target='_blank' rel={'noopener noreferrer'}>
								Aid to Syrians: How far does it go?
							</a>
						</ReadMore>
					</div>
					{/* START 2015 */}
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.center)
								filterData(2015)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<SyriaYearContainer>
							<h1 className={'year'}>2015</h1>
							<p className={'first-line'}>
								<strong>4.9 million Syrians</strong> are refugees. <br />
								6.6 million are internally displaced.
							</p>
							<p className={'second-line'}>
								<strong>17,717 Syrians</strong> are resettled in third countries.
							</p>
						</SyriaYearContainer>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.italyAndGreece)
								filterData(2015)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							Many Syrian refugees initially fled to places like Lebanon, Jordan, and Turkey, but with the longer-term hope of starting over in safe countries
							further afield, often via the UN’s resettlement programme. But the wait for resettlement is long, and only a fraction of families put forward by a
							UN screening process win a place. As the system often results in disappointment, many refugees try alternative, often deadly, routes. Some{' '}
							<a href='http://newirin.irinnews.org/the-long-way-round/' target='_blank' rel='noopener noreferrer'>
								even fly to Mauritania
							</a>{' '}
							and attempt to cross the Sahel for the chance to reach Europe. In 2015, more than a million people – five times the number the previous year –
							arrive in Italy and Greece by boat. Most are Syrians. Several European countries{' '}
							<a href='https://newirin.irinnews.org/race-to-beat-hungary-border-fence' target='_blank' rel='noopener noreferrer'>
								put up fences
							</a>{' '}
							to prevent migrants, including Syrians, from entering.
						</p>
					</div>
					<div className='step image'>
						<Waypoint
							onEnter={() => {
								filterData(2015)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('greece-migrant-life-vest'),
									caption: 'Life jackets and rubber dinghies thrown away in Lesvos. (Cristina Mastrandrea/TNH)',
								})
							}}
							onLeave={() => {
								setTimeout(() => {
									setShowOverlayPhoto(null)
								}, 800)
							}}
						/>
						<p>
							3,771 people die while trying to cross the Mediterranean, including three-year-old Alan Kurdi, who drowns with his mother and his brother when
							their smuggler’s boat capsizes. The image of his body, washed up on a Turkish beach, came to represent what became known as the “refugee crisis”.
							But refugees are much more than objects of pity. On the Greek island of Lesvos, newly arrived Syrian doctors, football coaches, hairdressers,
							computer scientists, and lawyers speak of their career ambitions, and what they have to offer Europe.
						</p>
						<ReadMore>
							Read more →{' '}
							<a href='https://www.thenewhumanitarian.org/news/2015/09/28' target='_blank' rel={'noopener noreferrer'}>
								Humans of Syria
							</a>
						</ReadMore>
					</div>
					{/* START 2016 */}
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.europeAndTurkey)
								filterData(2016)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<SyriaYearContainer>
							<h1 className={'year'}>2016</h1>
							<p className={'first-line'}>
								<strong>5.5 million Syrians</strong> are refugees. <br />
								6.3 million are internally displaced.
							</p>
							<p className={'second-line'}>
								<strong>65,843 Syrians</strong> are resettled in third countries.
							</p>
						</SyriaYearContainer>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.europeAndTurkey)
								filterData(2016)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							Syria’s war drags on, and a long and brutal campaign forces the last rebels out of Aleppo by the end of 2016. This year, a{' '}
							<a href='https://www.nytimes.com/2018/04/13/world/middleeast/syria-death-toll.html' target='_blank' rel={'noopener noreferrer'}>
								UN official
							</a>{' '}
							puts the death toll at around 400,000. But the number is still based on old figures. It was so{' '}
							<a href='https://www.thenewhumanitarian.org/node/255643' target='_blank' rel={'noopener noreferrer'}>
								hard to keep an accurate toll
							</a>{' '}
							that the UN’s official death count was stopped in 2014.
						</p>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.center)
								filterData(2016)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							Meanwhile, the{' '}
							<a
								href='https://www.thenewhumanitarian.org/special-report/2016/03/10/no-way-out-how-syrians-are-struggling-find-exit'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								options for people
							</a>{' '}
							trying to flee the country are dwindling. Jordan, Lebanon, and Syria have all instituted policies that tightly control new entries. In March, the
							EU signs a{' '}
							<a
								href='https://www.thenewhumanitarian.org/analysis/2016/06/03/eu-turkey-migration-deal-dying-what-s-plan-b'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								controversial deal
							</a>{' '}
							with Turkey to stem the flow of migrants into Europe. By October,{' '}
							<a
								href='https://www.thenewhumanitarian.org/special-report/2016/06/09/syrians-trapped-desert-no-man-s-land'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								some 70,000 people are stuck in the desert
							</a>{' '}
							on the border with Jordan in desperate conditions, unable to cross into Jordan and unwilling to return to danger. Some remain there to this day.
						</p>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.center)
								filterData(2016)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							While their immediate neighbours have become less welcoming, some Syrians have managed to take advantage of open-door policies further afield,
							ending up in some{' '}
							<a
								href='https://www.thenewhumanitarian.org/photo-feature/2021/2/3/french-guiana-cuba-migrants-EU-asylum-life-in-limbo'
								target='_blank'
								rel='noopener noreferrer'
							>
								{' '}
								unexpected places
							</a>
							, including...
						</p>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.mauritania)
								filterData(2016)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<div
							style={{
								position: 'relative',
								height: '0',
								paddingTop: '56.25%',
								width: '100%',
							}}
						>
							<GatsbyImage
								style={{
									height: '100%',
									width: '100%',
									position: 'absolute',
									top: 0,
									left: 0,
								}}
								imgStyle={{ height: '100%', width: '100%' }}
								image={getImageByName('Syria-data-mauritania-red')}
								alt={'Syria-data-mauritania-red'}
							/>
						</div>
						<p style={{ textAlign: 'center' }}>
							...{' '}
							<a href='https://www.thenewhumanitarian.org/feature/2016/05/31/visa-free-mauritania' target='_blank' rel={'noopener noreferrer'}>
								Mauritania
							</a>{' '}
							...
						</p>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.sudan)
								filterData(2016)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<div
							style={{
								position: 'relative',
								height: '0',
								paddingTop: '56.25%',
								width: '100%',
							}}
						>
							<GatsbyImage
								style={{
									height: '100%',
									width: '100%',
									position: 'absolute',
									top: 0,
									left: 0,
								}}
								imgStyle={{ height: '100%', width: '100%' }}
								image={getImageByName('Syria-data-sudan-red')}
								alt={'Syria-data-sudan-red'}
							/>
						</div>
						<p style={{ textAlign: 'center' }}>
							...{' '}
							<a href='https://www.thenewhumanitarian.org/feature/2016/05/31/shared-language-and-religion-sudan' target='_blank' rel={'noopener noreferrer'}>
								Sudan
							</a>{' '}
							...
						</p>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.brazil)
								filterData(2016)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<div
							style={{
								position: 'relative',
								height: '0',
								paddingTop: '56.25%',
								width: '100%',
							}}
						>
							<GatsbyImage
								style={{
									height: '100%',
									width: '100%',
									position: 'absolute',
									top: 0,
									left: 0,
								}}
								imgStyle={{ height: '100%', width: '100%' }}
								image={getImageByName('Syria-data-brazil-red')}
								alt={'Syria-data-brazil-red'}
							/>
						</div>
						<p style={{ textAlign: 'center' }}>
							... and{' '}
							<a href='https://www.thenewhumanitarian.org/feature/2016/05/31/peaceful-life-brazil' target='_blank' rel={'noopener noreferrer'}>
								Brazil
							</a>
							.
						</p>
					</div>
					<div className='step image'>
						<Waypoint
							onEnter={() => {
								filterData(2016)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('biro_rukban_056'),
									caption: 'Thousands of people are camped on a no man’s-land known between Jordan and Syria. (Peter Biro/ECHO)',
								})
							}}
							onLeave={() => {
								setTimeout(() => {
									setShowOverlayPhoto(null)
								}, 800)
							}}
						/>
						<p>
							Some 172,000 refugees, including 65,000 Syrians, are resettled worldwide in 2016, the largest number since 1990. This includes people going to
							Canada, which since the 1970s has allowed groups of people to sponsor the resettlement of individual refugees or families. In addition to making a
							financial commitment, sponsors provide practical and emotional support as the new arrivals ease into their homes. This private sponsorship
							programme takes off in 2016. Iman, Zaher, and their two daughters arrive in Ottawa via Toronto and Beirut, not speaking a word of English, greeted
							by the 38 Canadians who banded together to sponsor their trip.
						</p>
						<ReadMore>
							Read more →{' '}
							<a href='https://www.thenewhumanitarian.org/feature/2016/07/14/canada-s-refugee-success-story' target='_blank' rel={'noopener noreferrer'}>
								Canada’s refugee success story
							</a>
						</ReadMore>
					</div>
					{/* START 2017 */}
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.center)
								filterData(2017)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<SyriaYearContainer>
							<h1 className={'year'}>2017</h1>
							<p className={'first-line'}>
								<strong>6.3 million Syrians</strong> are refugees. <br />
								6.2 million are internally displaced.
							</p>
							<p className={'second-line'}>
								<strong>40,192 Syrians</strong> are resettled in third countries.
							</p>
						</SyriaYearContainer>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.romaniaAndTurkey)
								filterData(2017)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							Following the 2016 EU-Turkey deal, informal migration to Europe drops sharply as high-tech security fences are erected along the Bulgarian-Turkish
							border, and increased naval patrols monitor the Aegean Sea. To avoid these obstacles, smugglers begin taking people on boats from Turkey to
							Romania,{' '}
							<a href='https://www.thenewhumanitarian.org/fr/node/259865' target='_blank' rel={'noopener noreferrer'}>
								plying the rough Black Sea waters
							</a>
							.
						</p>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.romaniaAndTurkey)
								filterData(2017)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							Most people who arrive in Romania hope to seek asylum elsewhere, but some are returned to the country by the “Dublin Regulation”, which gives
							member states the right to return asylum seekers to their first country of arrival in the EU. This happens across Europe. For example, Anas, a
							27-year-old Syrian who escaped besieged Eastern Ghouta, took a smuggler’s boat from Libya to Lampedusa, and then eventually made it to Sweden and
							then Germany before being arrested and deported back to Italy – this was despite having a letter from a doctor saying his medical state was so
							severe he should remain in Germany for treatment.
						</p>
					</div>
					<div className='step image'>
						<Waypoint
							onEnter={() => {
								filterData(2017)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('the-coach-departs'),
									caption:
										'Syrians who have been intercepted by Hungarian police are sent to a temporary camp for asylum seekers while the government decides where to send them. (András D Hajdú/TNH)',
								})
							}}
							onLeave={() => {
								setTimeout(() => {
									setShowOverlayPhoto(null)
								}, 800)
							}}
						/>
						<p>
							Back in Italy, Anas was fingerprinted by police, and handed some paperwork. “I asked them where I should go. I was bleeding. Really, there was
							blood everywhere on my pants… They said, ‘Go sleep in the street’. I didn’t have any money. I couldn’t sleep in a hotel. Where was I supposed to
							sleep?”
						</p>
						<ReadMore>
							Read more →{' '}
							<a
								href='https://www.thenewhumanitarian.org/special-report/2017/11/21/how-fingerprint-can-change-asylum-seeker-s-life'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								How a fingerprint can change an asylum seeker’s life
							</a>
						</ReadMore>
					</div>

					{/* START 2018 */}
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.center)
								filterData(2018)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<SyriaYearContainer>
							<h1 className={'year'}>2018</h1>
							<p className={'first-line'}>
								<strong>6.7 million</strong> Syrians are refugees. <br />
								6.2 million are internally displaced.
							</p>
							<p className={'second-line'}>
								<strong>34,126 Syrians</strong> are resettled in third countries.
							</p>
						</SyriaYearContainer>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.usa)
								filterData(2018)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							Despite a new pact, and{' '}
							<a
								href='https://www.thenewhumanitarian.org/news/2016/09/21/plenty-hype-no-new-ideas-un-migration-summit'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								promises
							</a>{' '}
							to take in more refugees from around the globe,{' '}
							<a href='https://www.thenewhumanitarian.org/2018/09/13/refugee-resettlement-hits-10-year-low' target='_blank' rel={'noopener noreferrer'}>
								refugee resettlement drops off
							</a>
							, hitting a 10-year low in 2018. The drop is mainly due to President Donald Trump’s{' '}
							<a
								href='https://www.thenewhumanitarian.org/analysis/2017/01/30/updated-trump-action-derails-global-refugee-resettlement-efforts'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								decision to slash
							</a>{' '}
							refugee admissions to the United States.
						</p>
					</div>
					<div className='step image'>
						<Waypoint
							onEnter={() => {
								filterData(2018)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								// setShowOverlayPhoto({image: getImageByName('afp_photo_yarmouk'), caption: 'some caption'})
								setShowOverlayPhoto({
									image: getImageByName('Syria-data-2018-yarmouk'),
									caption:
										'In 2014, some 18,000 Palestine refugees were trapped in the Damascus Yarmouk refugee camp without adequate food, water, medicine, and with extremely limited aid deliveries. (UNRWA)',
								})
							}}
							onLeave={() => {
								setTimeout(() => {
									setShowOverlayPhoto(null)
								}, 800)
							}}
						/>
						<p>
							At the beginning of the year,{' '}
							<a
								href='https://www.thenewhumanitarian.org/analysis/2017/12/19/man-made-disaster-syria-s-eastern-ghouta'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								hundreds of thousands of people
							</a>{' '}
							are trapped in the rebel enclave of{' '}
							<a href='https://www.thenewhumanitarian.org/analysis/2018/02/23/understanding-eastern-ghouta-syria' target='_blank' rel={'noopener noreferrer'}>
								Eastern Ghouta
							</a>
							, running desperately low on food and medical supplies as they are besieged and bombed by government forces. The siege ends in April as rebels are
							evacuated. In the same month, the Palestinian refugee camp of Yarmouk – besieged for years and now controlled by IS – is hit with airstrikes,
							forcing thousands of civilians to flee. Some of the Palestinians join Syrian refugees in neighbouring countries, especially Lebanon. “The
							situation facing Palestine refugees in and around Yarmouk is unimaginable,” says a spokesperson for the UN’s Palestine refugee agency, UNRWA. “In
							Yarmouk itself, thousands of homes have been destroyed and the last remaining hospital [has] been rendered inoperable by fighting. We call on all
							sides to spare civilian life and infrastructure. The situation they face is piteous.”
						</p>
						<ReadMore>
							Read more →{' '}
							<a
								href='https://www.thenewhumanitarian.org/news/2018/04/23/civilians-trapped-yarmouk-face-unimaginable-end-siege'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								Civilians trapped in Yarmouk face 'unimaginable' end to siege
							</a>
						</ReadMore>
					</div>

					{/* START 2019 */}
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.center)
								filterData(2019)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<SyriaYearContainer>
							<h1 className={'year'}>2019</h1>
							<p className={'first-line'}>
								<strong>6.6 million</strong> Syrians are refugees. <br />
								6.1 million are internally displaced.
							</p>
							<p className={'second-line'}>
								<strong>30,686 Syrians</strong> are resettled in third countries.
							</p>
						</SyriaYearContainer>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.lesvos)
								filterData(2019)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							Governments and politicians in{' '}
							<a
								href='https://www.thenewhumanitarian.org/news-feature/2018/12/11/Syria-refugees-Lebanon-Mediterranean-drowning-Europe-Middle-East-smuggling'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								Lebanon
							</a>{' '}
							and{' '}
							<a
								href='https://www.thenewhumanitarian.org/news-feature/2019/08/20/Istanbul-Syrians-Turkey-time-hoping-and-hiding'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								Turkey
							</a>{' '}
							enforce increasingly{' '}
							<a
								href='https://www.thenewhumanitarian.org/news-feature/2019/09/17/Syrian-refugees-deportations-Lebanon'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								harsh restrictions
							</a>{' '}
							on Syrians, as{' '}
							<a
								href='https://www.thenewhumanitarian.org/feature/2018/03/27/syrian-refugees-turkey-face-calls-return-public-mood-changes'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								public calls
							</a>{' '}
							grow for refugees to return. Some people find refugee life so hard they decide to{' '}
							<a href='https://www.thenewhumanitarian.org/news-feature/2019/11/19/Syrian-refugees-return-Jordan' target='_blank' rel={'noopener noreferrer'}>
								take their chances{' '}
							</a>
							and go back to Syria. Meanwhile,{' '}
							<a href='https://www.thenewhumanitarian.org/news/2019/09/25/Greek-migrant-centre-arrivals-soar' target='_blank' rel={'noopener noreferrer'}>
								conditions at the Moria reception centre
							</a>{' '}
							on the Greek island of Lesvos deteriorate, as 12,000 people – more than four times the number it was designed for – shelter there. People are
							packed into shipping containers or makeshift tents, and allegations of rape are common.
						</p>
					</div>
					<div className='step image'>
						<Waypoint
							onEnter={() => {
								filterData(2019)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('syria-idlib-ceasefire-hospital-photo'),
									caption: 'The Adnan Kiwan hospital in rural southern Idlib. It was bombed in November 2019. (Muhammad Al Hosse/TNH)',
								})
							}}
							onLeave={() => {
								setTimeout(() => {
									setShowOverlayPhoto(null)
								}, 800)
							}}
						/>
						<p>
							Inside Syria, smugglers report a growing demand for their services as a Russian-backed offensive by al-Assad’s forces in rebel-held Idlib leads to
							a drastic increase in violence. Scrambling to get into Turkey, people like Omar Mubarak are selling their belongings and risking the potential of
							being shot or turned back at the border, often trying multiple times. “I’m living in a state of constant anxiety,” says Mubarak of his failed
							attempts to cross with his wife. “Despite all this tragedy and danger, even this is better than staying under the bombs.”
						</p>
						<ReadMore>
							Read more →{' '}
							<a
								href='https://www.thenewhumanitarian.org/news-feature/2019/06/25/syria-idlib-smugglers-escape-turkey'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								Smugglers report booming market as people try to escape Syria to Turkey
							</a>
						</ReadMore>
					</div>

					{/* START 2020 */}
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.center)
								filterData(2020)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<SyriaYearContainer>
							<h1 className={'year'}>2020</h1>
							<p className={'first-line'}>
								<strong>6.6 million</strong> Syrians are refugees. <br />
								6.7 million are internally displaced.
							</p>
							<p className={'second-line'}>
								<strong>9,377 Syrians</strong> are resettled in third countries.
							</p>
						</SyriaYearContainer>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.center)
								filterData(2020)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
							}}
						/>
						<p>
							UNHCR says 2020 marks the{' '}
							<a
								href='https://www.unhcr.org/uk/news/press/2021/1/600e79ea4/refugee-resettlement-record-low-2020-unhcr-calls-states-offer-places-save.html'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								lowest number of refugee resettlements
							</a>{' '}
							by the UN in two decades, partly due to the pandemic. By the end of the year, 9,377 Syrians are resettled – in Canada, Germany, Sweden, and 17
							other countries.
						</p>
					</div>
					<div className='step image'>
						<Waypoint
							onEnter={() => {
								filterData(2020)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('syria-idlib-covid-19-2'),
									caption:
										'An aid worker from the Syrian NGO Ataa Relief shows a child at an Idlib camp how to put on a protective mask. (Muhammad Al Hosse/TNH)',
								})
							}}
							onLeave={() => {
								// setShowOverlayPhoto(null)
							}}
						/>
						<p>
							Over the course of the government offensive to retake Idlib,{' '}
							<a
								href='https://www.thenewhumanitarian.org/photo-feature/2020/1/13/Syria-Idlib-ceasefire-healthcare-hospitals'
								target='_blank'
								rel='noopener noreferrer'
							>
								hospitals
							</a>{' '}
							and, by the time a ceasefire is announced in March nearly a million people have been forced to take flight in and around the province in just a
							few months. For many, this is not their first displacement in Syria’s long war. Having taken shelter in makeshift camps, people who have escaped
							one deadly front line are now faced with a new threat: COVID-19. Nisreen al-Mahmoud, who fled bombardment in her hometown, is living in a tent
							with her son. “I can’t sleep at night anymore. My mind is occupied with how I’m going to protect myself and stay away from other people during
							this period, since I heard that we shouldn’t mix with others,” she says. “I’m afraid of dying, and I hope it’s not from this illness.”
						</p>
						<ReadMore>
							Read more →{' '}
							<a href='https://www.thenewhumanitarian.org/news-feature/2020/03/26/syria-idlib-coronavirus' target='_blank' rel={'noopener noreferrer'}>
								Bracing for coronavirus in Syria’s battered northwest
							</a>
						</ReadMore>
					</div>

					{/* START 2021 */}
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.center)
								filterData(2021)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('v-p-sy-e-00798'),
									caption: 'Conflict in Moadamiyeh, rural Damascus, left vast destruction in its wake, 2018. (Ali Yousef/ICRC)',
								})
							}}
						/>
						<SyriaYearContainer>
							<h1 className={'year'}>2021</h1>
							<p className={'first-line'}>
								<strong>6.6 million</strong> Syrians are refugees. <br />
								6.7 million are internally displaced.
							</p>
							{/* <p className={'second-line'}>
                <strong>Number</strong> of resettlements.
              </p> */}
						</SyriaYearContainer>
					</div>
					<div className='step image'>
						<Waypoint
							onEnter={() => {
								filterData(2021)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('v-p-sy-e-00798'),
									caption: 'Conflict in Moadamiyeh, rural Damascus, left vast destruction in its wake, 2018. (Ali Yousef/ICRC)',
								})
							}}
							onLeave={() => {
								// setShowOverlayPhoto(null)
							}}
						/>
						<p>
							The new US administration{' '}
							<a
								href='https://www.npr.org/2021/03/04/973763365/u-n-refugee-agency-official-ecstatic-at-biden-plan-to-reboot-refugee-resettlemen'
								target='_blank'
								rel={'noopener noreferrer'}
							>
								pledges to allow 125,000 refugees
							</a>{' '}
							into the United States per year, in contrast to the 12,000 (including only 365 Syrians) admitted in 2020. President Joe Biden’s plan{' '}
							<a href='https://www.reuters.com/article/us-usa-biden-immigration-refugees-factbo-idUSKBN2A92OE' target='_blank' rel={'noopener noreferrer'}>
								mentions refugees from Syria
							</a>
							, where it says “regime forces have forcibly displaced, raped, starved, and massacred civilians.” A private sponsorship scheme similar to Canada’s
							will also be piloted.
						</p>
					</div>
					<div className='step'>
						<Waypoint
							onEnter={() => {
								setMapPosition(coordinates.center)
								filterData(2021)
								setShowRefugeesData(true)
								setShowRefugeesResettledData(true)
								setShowOverlayPhoto({
									image: getImageByName('v-p-sy-e-00798'),
									caption: 'Conflict in Moadamiyeh, rural Damascus, left vast destruction in its wake, 2018. (Ali Yousef/ICRC)',
								})
							}}
						/>
						<p>
							After 10 years of war, the vast majority of Syrians who fled horrific violence, abuse, and repression remain relatively close to home, either in
							the region or inside the country. A lucky few are in the queue for a new beginning in a new country. But most are in limbo, unable to build a new
							life where they are, and often unwilling to go back to the dangers they know – and the risks they fear – in a homeland in ruins.
						</p>
					</div>
				</ScrollMap>

				<TextContainerComponent>
					<h2>Data disclaimer</h2>
					<p>
						*Source of{' '}
						<a href='https://rsq.unhcr.org/en/' target='_blank' className={'underlined'} rel={'noopener noreferrer'}>
							resettlement
						</a>{' '}
						and{' '}
						<a href='https://www.unhcr.org/refugee-statistics/download/' target='_blank' className={'underlined'} rel={'noopener noreferrer'}>
							refugee
						</a>{' '}
						data is the UN’s refugee agency, UNHCR.
					</p>
					<p>
						In addition to the 201,000 Syrians refugees resettled through UNHCR, some 94,000 have been naturalised between 2010 and 2020, including about 79,000
						in Turkey, 10,000 in Canada, and 3,800 in the Netherlands. Refugee figures include asylum seekers, and “others of concern” to the UNHCR.
					</p>
					<p style={{ fontStyle: 'italic' }}>
						Top photo: Refugees who have walked into Hungary from Serbia, hoping to avoid a crackdown on migration. (András D Hajdú/TNH)
					</p>
					<p style={{ fontStyle: 'italic' }}>Compiled and edited by Annie Slemrod and Ben Parker. Graphics and layout by Marc Fehr.</p>
				</TextContainerComponent>
				<ScrollToTop href='#start'>
					<button className='js-return-to-top site-footer__return-button'>Return to top</button>
				</ScrollToTop>
			</ContainerComponent>
		</Layout>
	)
}

export const query = graphql`
	query SyrianRefugeesImages {
		allFile(
			filter: {
				sourceInstanceName: { eq: "images" }
				extension: { in: ["jpg", "png", "jpeg", "webp"] }
				relativePath: { glob: "stories/2021/a-decade-of-war-in-syria/*" }
			}
		) {
			edges {
				node {
					extension
					dir
					modifiedTime
					name
					relativePath
					childImageSharp {
						gatsbyImageData(
							width: 1280,
							quality: 85,
							placeholder: BLURRED,
							layout: FULL_WIDTH
						)
						# fluid(maxWidth: 1280, quality: 100) {
						#   srcSetWebp
						#   base64
						#   srcWebp
						#   ...GatsbyImageSharpFluid
						# }
						# original {
						#   src
						# }
						# fixed(duotone: { highlight: "#9f3e52", shadow: "#282828" }) {
						#   base64
						#   srcWebp
						#   srcSetWebp
						#   ...GatsbyImageSharpFixed
						# }
					}
				}
			}
		}
	}
`

export default SyriaStory
